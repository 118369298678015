import * as React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureTab = React.forwardRef<any, any>((props: any, ref) => {
  return (
    <Box py="4" px="2">
      <Stack direction="row" w="100%" spacing="6">
        {props.icon && (
          <FontAwesomeIcon
            icon={props.icon}
            size="3x"
            style={{ marginTop: "10px" }}
          />
        )}
        <Stack spacing="0">
          <Text
            textAlign="left"
            as="h2"
            lineHeight="1.2"
            fontSize="3xl"
            fontWeight="bold"
            fontFamily="Arvo"
          >
            {props.title}
          </Text>
          <Text size="lg" pr="6" lineHeight="tall" textAlign="left">
            {props.children}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
});

export default FeatureTab;
